import React from 'react';

/**
 * 改行コードをJSXに変換する
 * @param {string} text 変換するテキスト
 * @returns {string} 変換後のJSX
 */
function nlToBr(text) {
    return text.split('\n').map(function (item, key) {
        return (
            <span key={key}>
                {item}
                <br />
            </span>
        )
    })
}

export default nlToBr;