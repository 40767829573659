import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import getJwtToken from '../../../utils/getJwtToken';

import apiWorkspaceConfig from '../../../api/admin/workspaceConfig';

const initState = {
    form: {
        distribute_point: 0
    }
};

class WorkspaceConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: initState.form
        }
        this.fetchConfig = this.fetchConfig.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
    }
    componentDidMount(){
        this.fetchConfig();
    }

    fetchConfig() {
        getJwtToken().then(jwtToken => apiWorkspaceConfig.get(jwtToken))
            .then(res => res.json())
            .then(res => {
                this.setState({ form: res ? res : {} })
            }).catch(error => {
                console.error(error.response)
            });
    }

    handleInput(e) {
        const { name, value } = e.target;
        const form = Object.assign({}, this.state.form);
        form[name] = value;
        this.setState({ form });
    }

    updateConfig() {
        const { distribute_point, distribute_freq } = this.state.form;
        getJwtToken().then(jwtToken => apiWorkspaceConfig.update(jwtToken, distribute_point, distribute_freq)).then(this.fetchConfig);
    }

    render() {
        return (
            <div>
                <h2>ワークスペース設定</h2>
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>配布ポイント</Form.Label>
                        <Form.Control type="number" placeholder="配布ポイント" name="distribute_point" value={this.state.form.distribute_point} onChange={this.handleInput}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>ポイント配布頻度</Form.Label>
                        <Form.Control as="select" name="distribute_freq" value={this.state.form.distribute_freq} onChange={this.handleInput}>
                            <option value=''>選択してください</option>
                            <option value='min'>毎分</option>
                            <option value='week'>毎週</option>
                            <option value='month'>毎月</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                <Button onClick={this.updateConfig}>更新</Button>
            </div>
        );
    }
}
export default WorkspaceConfig;