import React, { Component } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import getJwtToken from '../../../utils/getJwtToken';

import apiUser from '../../../api/user';
import apiAdminUser from '../../../api/admin/user';

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            modal: {
                show: false,
                email: '',
                username: ''
            }
        }
        this.fetchUsers = this.fetchUsers.bind(this);
        this.disableUser = this.disableUser.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }
    componentDidMount() {
        this.fetchUsers();
    }
    fetchUsers() {
        getJwtToken().then(jwtToken => apiUser.get(jwtToken))
            .then(res => res.json())
            .then(res => {
                this.setState({ users: res.length > 0 ? res : [] })
            }).catch(error => {
                console.error(error.response)
            });
    }

    disableUser(email) {
        getJwtToken().then(jwtToken => apiAdminUser.disable(jwtToken, email)).then(this.fetchUsers);
    }

    handleModalShow(email, username) {
        const modal = Object.assign({}, this.modal);
        modal.show = true;
        modal.email = email;
        modal.username = username;
        this.setState({ modal });
    }

    handleModalClose() {
        const modal = Object.assign({}, this.modal);
        modal.show = false;
        modal.email = '';
        modal.username = '';
        this.setState({ modal });
    }

    render() {
        return (
            <div>
                <h2>ユーザー一覧</h2>
                <Table>
                    <thead>
                        <tr><th>email</th><th>username</th><th>point_to_give</th><th>received_point</th><th>無効化</th></tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((item, index) => {
                            return <tr key={index}><td>{item.email}</td><td>{item.name}</td><td>{item.point_to_give}</td><td>{item.received_point}</td><td><Button onClick={() => this.handleModalShow(item.email, item.name)} >X</Button></td></tr>
                        })}
                    </tbody>
                </Table>
                <Modal show={this.state.modal.show} onHide={this.handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>ユーザーの無効化</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>以下のユーザを無効化してもよろしいですか？</p>
                        <ul>
                            <li>{this.state.modal.username} : {this.state.modal.email}</li>
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                            キャンセル
                        </Button>
                        <Button variant="primary" onClick={() => {
                            this.disableUser(this.state.modal.email);
                            this.handleModalClose();
                        }}>
                            無効化
                        </Button>
                    </Modal.Footer>

                </Modal>
            </div>
        );
    }
}
export default User;