import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment-timezone';
import nlToBr from '../../utils/nl-to-br';

import sampleImg from '../../assets/img/erapo_square_short.png';
import arrowImg from '../../assets/img/arrow.svg';

const UserImg = styled.img`
    width:50%;
    border-radius: 50%;
`

const UserName = styled.div`
    margin-top: 10px;
`

const UserItemContainer = styled.div`
    text-align: center;
`

const UserItem = (props) => {
    const initial = props.username ? props.username.charAt(0) : '-';
    const img = `http://placehold.jp/150x150.png?text=${initial}&css={"font-size":"80px"}`
    return (
        <UserItemContainer>
            <UserImg src={img} alt={props.username} />
            <UserName>{props.username}</UserName>
        </UserItemContainer>
    )
}

const Arrow = styled.img`
    width:100%;
    margin-bottom:20px;
`

const Point = styled.div`
    text-align:center;
    font-size:1.8rem;
`

const ItemContainer = styled.div`
   box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
   border-radius:6px;
   margin:20px 0;
   padding:20px 40px;
`

const Message = styled.p`
    margin-top:20px;
    margin-bottom:20px;
    @media (min-width: 992px) {
        margin-top:0;
        margin-bottom:0;
    }
`

const Time = styled(Col)`
    text-align:right;
    margin-bottom:20px;
`

const getTime = (datetime) => {
    const target = moment(datetime);
    const current = moment();

    if (target.isSame(current, 'minute')) {
        const offset = current.second() - target.second();
        return `${offset}秒前`;
    } else if (target.isSame(current, 'hour')) {
        const offset = current.minute() - target.minute();
        return `${offset}分前`;
    } else if (target.isSame(current, 'date')) {
        const offset = current.hour() - target.hour();
        return `${offset}時間前`;
    } else if (target.isSame(current, 'month')) {
        const offset = current.date() - target.date();
        return `${offset}日前`;
    } else if (target.isSame(current, 'year')) {
        const offset = current.month() - target.month();
        return `${offset}ヶ月前`;
    } else {
        const offset = current.year() - target.year();
        return `${offset}年前`;
    }
}

const GiveItem = (props) => (
    <ItemContainer>
        <Row><Time>{getTime(props.datetime)}</Time></Row>
        <Row>
            <Col xs={12} lg={6}>
                <Row className="align-items-center">
                    <Col xs={4}><UserItem username={props.from_user_name} img={sampleImg} /></Col>
                    <Col xs={4}><Point>{props.point}</Point><Arrow src={arrowImg} alt="arrow" /></Col>
                    <Col xs={4}><UserItem username={props.to_user_name} img={sampleImg} /></Col>
                </Row>
            </Col>
            <Col xs={12} lg={6}><Message>{nlToBr(props.message)}</Message></Col>
        </Row>
    </ItemContainer>
);
export default GiveItem;