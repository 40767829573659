const getBaseUrl = require('./util/getBaseUrl');
const baseUrl = getBaseUrl();

const get = (Authorization, fromUser, toUser, limit) => {
    let url = baseUrl + '/give_log';
    const params = [];
    if (fromUser) params.push(`?from_user=${fromUser}`)
    if (toUser) params.push(`?to_user=${toUser}`)
    if (limit) params.push(`limit=${limit}`)
    url += '?' + params.join('&');
    const init = {
        headers: {
            Authorization
        }
    }
    return fetch(url, init);
}

const aggregatePointByUser = (Authorization, toUser, minDate, maxDate) => {
    const url = new URL(baseUrl + '/give_log/aggregatePointByUser');
    const params = {};
    if (toUser) params.to_user = toUser;
    if (minDate) params.min_date = minDate;
    if (maxDate) params.max_date = maxDate;
    url.search = new URLSearchParams(params)
    const init = {
        headers: {
            Authorization
        }
    }
    return fetch(url, init);
}

export default {
    get,
    aggregatePointByUser
}