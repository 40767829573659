import React, { Component } from 'react';
import getJwtToken from '../../utils/getJwtToken';

import GiveItem from '../common/GiveItem';

import apiGiveLog from '../../api/give_log';

class Received extends Component {
    constructor(props) {
        super(props);
        this.state = {
            received: []
        }
        this.fetchReceived = this.fetchReceived.bind(this);
    }
    componentDidMount() {
        this.fetchReceived();
    }
    fetchReceived() {
        getJwtToken().then(jwtToken => apiGiveLog.get(jwtToken, null, this.props.email))
            .then(res => res.json())
            .then(res => {
                this.setState({ received: res.length > 0 ? res : [] })
            }).catch(error => {
                console.error(error.response)
            });
    }
    render() {
        return (
            <div>
                <h2>もらった</h2>
                {this.state.received.map((item, index) => {
                    return <GiveItem key={index} {...item} />
                })}
                {(!this.state.received || !this.state.received.length) && <p>データがありません。</p>}
            </div>
        );
    }
}
export default Received;