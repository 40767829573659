import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import logo from '../../assets/img/erapo_rectangle_transparent.svg';

const Wrapper = styled.div`
    background-color: #ffe21f;
    min-height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;
`

const LogoContainer = styled.div`
    width: 100%;
    text-align: center;
`

const Logo = styled.img`
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
`;

class CustomForgotPassword extends ForgotPassword {
    sendView() {
        return (
            <div>
                <Form>
                    <Form.Group controlId="sendEmail">
                        <Form.Label>ユーザ名</Form.Label>
                        <Form.Control type="text" key="username" name="username" placeholder="ユーザ名" autoFocus onChange={this.handleInputChange} />
                    </Form.Group>
                </Form>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button variant="link" onClick={() => this.changeState('signIn')}>サインイン</Button>
                        <Button variant="outline-main" onClick={this.send}>コード送信</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    submitView() {
        return (
            <div>
                <Form>
                    <Form.Group controlId="submitEmail">
                        <Form.Label>コード</Form.Label>
                        <Form.Control type="text" key="code" name="code" autoComplete="off" placeholder="コード" autoFocus onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group controlId="submitPassword">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Control type="password" key="password" name="password" placeholder="パスワード" onChange={this.handleInputChange} />
                    </Form.Group>
                </Form>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button variant="link" onClick={() => this.changeState('signIn')}>サインイン</Button>
                        <Button variant="outline-main" onClick={this.submit}>送信</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    showComponent() {
        return (
            <Wrapper>
                <Container>
                    <LogoContainer>
                        <Logo src={logo} />
                    </LogoContainer>
                    {this.state.delivery ? this.submitView() : this.sendView()}
                </Container>
            </Wrapper>
        )
    }
}
export default CustomForgotPassword;