import React, { Component } from 'react';
import getJwtToken from '../../utils/getJwtToken';

import GiveItem from '../common/GiveItem';

import apiGiveLog from '../../api/give_log';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            log: []
        }
        this.fetchLog = this.fetchLog.bind(this);
    }
    componentDidMount() {
        this.fetchLog(10);
    }

    fetchLog(limit) {
        getJwtToken().then(jwtToken => apiGiveLog.get(jwtToken, null, null, limit)).then((res) => res.json()).then((res) => {
            this.setState({ log: res });
        })
    }

    render() {
        return (
            <div>
                <h2>最近のやりとり</h2>
                {this.state.log.map((item, index) => {
                    return <GiveItem key={index} {...item} />
                })}
            </div>
        );
    }
}
export default Index;