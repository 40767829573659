const getBaseUrl = require('./util/getBaseUrl');
const baseUrl = getBaseUrl();

export default function (Authorization, from_user, to_user, point, message) {
    const url = baseUrl + '/give';
    const init = {
        method: 'POST',
        headers: {
            Authorization,
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            from_user,
            to_user,
            point,
            message
        })
    }
    return fetch(url, init);
}