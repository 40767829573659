import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import apiUser from './api/user';
import getJwtToken from './utils/getJwtToken';

import Navbar from './component/common/Navbar';

import Index from './component/Index';
import Give from './component/Give';
import Gave from './component/Gave';
import Received from './component/Received';
import AdminAggregate from './component/Admin/Aggregate';
import AdminUser from './component/Admin/User';
import AdminUserAdd from './component/Admin/User/Add';
import AdminWorkspaceConfig from './component/Admin/WorkspaceConfig';

import SignIn from './component/Auth/SignIn';
import ForgotPassword from './component/Auth/ForgotPassword';
import RequireNewPassword from './component/Auth/RequireNewPassword';
import VerifyContact from './component/Auth/VerifyContact';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { withAuthenticator, ConfirmSignIn, SignUp, ConfirmSignUp } from 'aws-amplify-react';
Amplify.configure(aws_exports);

const MainContainer = styled.main`
    padding-top:2rem;
    padding-bottom:2rem;
`

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            isAdminUser: false,
            point_to_give: 0,
            received_point: 0
        }
        this.signout = this.signout.bind(this);
        this.fetchLoginUser = this.fetchLoginUser.bind(this);
    }

    componentDidMount() {
        Auth.currentSession().then((r) => {
            const { payload } = r.idToken;
            this.setState({
                username: payload["name"],
                email: payload["email"],
                isAdminUser: payload["custom:role"] === 'admin'
            })
        });
        this.fetchLoginUser();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.email !== '' && this.state.email !== prevState.email) this.fetchLoginUser();
    }

    signout() {
        Auth.signOut().then(() => {
            this.setState({
                username: ''
            })
        });
    }

    fetchLoginUser() {
        getJwtToken().then((jwtToken) => apiUser.get(jwtToken, this.state.email)).then((res) => {
            return res.json();
        }).then((res) => {
            const { point_to_give, received_point } = res[0];
            this.setState({
                point_to_give,
                received_point
            })
        });
    }

    render() {
        return (
            <Router>
                <div>
                    <Navbar {...this.state} signout={this.signout} fetchLoginUser={this.fetchLoginUser} />
                    <Container>
                        <MainContainer>
                            <Route path="/" exact render={() => <Index {...this.state} fetchLoginUser={this.fetchLoginUser} />} />
                            <Route path="/give" render={() => <Give {...this.state} fetchLoginUser={this.fetchLoginUser} />} />
                            <Route path="/gave" render={() => <Gave {...this.state} />} />
                            <Route path="/received" render={() => <Received {...this.state} />} />
                            <Route path="/admin/aggregate" exact render={() => <AdminAggregate {...this.state} />} />
                            <Route path="/admin/user" exact render={() => <AdminUser {...this.state} />} />
                            <Route path="/admin/user/add" render={() => <AdminUserAdd {...this.state} />} />
                            <Route path="/admin/workspace-config" render={() => <AdminWorkspaceConfig {...this.state} />} />
                        </MainContainer>
                    </Container>
                </div>
            </Router>
        );
    }
}

export default withAuthenticator(App, false, [
    <SignIn />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <SignUp />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <RequireNewPassword />]);
