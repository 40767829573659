import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import logo from '../../assets/img/erapo_rectangle_transparent.svg';

const Wrapper = styled.div`
    background-color: #ffe21f;
    min-height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;
`

const LogoContainer = styled.div`
    width: 100%;
    text-align: center;
`

const Logo = styled.img`
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
`;

class CustomSignIn extends SignIn {
    showComponent(theme) {
        return (
            <Wrapper>
                <Container>
                    <LogoContainer>
                        <Logo src={logo} />
                    </LogoContainer>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>ユーザ名</Form.Label>
                            <Form.Control type="text" key="username" name="username" placeholder="ユーザ名" autoFocus onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>パスワード</Form.Label>
                            <Form.Control type="password" key="password" name="password" placeholder="パスワード" onChange={this.handleInputChange} />
                        </Form.Group>
                    </Form>
                    <Row className="justify-content-end">
                        <Col xs="auto">
                            <Button variant="link" onClick={() => this.changeState('forgotPassword')}>パスワードを忘れた</Button>
                            <Button variant="outline-main" onClick={this.signIn}>サインイン</Button>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
        )
    }
}
export default CustomSignIn;