import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

Amplify.configure({
    Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_QuCYxvnXz',
        userPoolWebClientId: '1idfsjhg29nute9vip3gfomfqk',
    }
});

library.add(faSyncAlt)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
