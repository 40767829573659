import React from 'react';
import { VerifyContact } from 'aws-amplify-react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import logo from '../../assets/img/erapo_rectangle_transparent.svg';

const Wrapper = styled.div`
    background-color: #ffe21f;
    min-height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;
`

const LogoContainer = styled.div`
    width: 100%;
    text-align: center;
`

const Logo = styled.img`
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
`;

class CustomVerifyContact extends VerifyContact {

    verifyView() {
        const { authData } = this.props;
        if (!authData) {
            return null;
        }
        const { unverified } = authData;
        if (!unverified) {
            return null;
        }

        // 認証対象のハードコーディング
        this.inputs['contact'] = true;
        this.inputs['checkedValue'] = 'email';

        return (
            <div>
                <p>パスワード復旧のため、メールの認証が必要です。</p>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button variant="link" onClick={() => this.changeState('signedIn', authData)}>スキップ</Button>
                        <Button variant="outline-main" onClick={this.verify}>認証</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    submitView() {
        const { authData } = this.props;
        return (
            <div>
                <Form>
                    <p>メールで認証コードをお送りしました。入力をして認証を完了してください。</p>
                    <Form.Group controlId="formCode">
                        <Form.Label>認証コード</Form.Label>
                        <Form.Control type="text" key="code" name="code" placeholder="認証コード" autoFocus autoComplete="off" onChange={this.handleInputChange} />
                    </Form.Group>
                </Form>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button variant="link" onClick={() => this.changeState('signedIn', authData)}>スキップ</Button>
                        <Button variant="outline-main" onClick={this.submit}>コード送信</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    showComponent() {
        return (
            <Wrapper>
                <Container>
                    <LogoContainer>
                        <Logo src={logo} />
                    </LogoContainer>
                    {this.state.verifyAttr ? this.submitView() : this.verifyView()}
                </Container>
            </Wrapper>
        );
    }
}
export default CustomVerifyContact;