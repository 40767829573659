import React, { Component } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import getJwtToken from '../../utils/getJwtToken';

import apiUser from '../../api/user';
import apiGive from '../../api/give';

const initState = {
    users: [],
    form: {
        to: '',
        point: 0,
        message: ''
    },
    error: {
        to: '',
        point: '',
        message: ''
    }
}

class Give extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            form: initState.form,
            error: initState.error
        }
        this.fetchUsers = this.fetchUsers.bind(this);
        this.give = this.give.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.clearError = this.clearError.bind(this);
    }
    componentDidMount() {
        this.fetchUsers();
    }
    fetchUsers() {
        getJwtToken().then(jwtToken => apiUser.get(jwtToken)).then(res => res.json()).then(res => {
            this.setState({ users: res })
        }).catch(error => {
            console.error(error.response)
        });
    }
    give() {
        const hasError = this.validateForm();
        if (hasError) return;
        const { to, point, message } = this.state.form;
        getJwtToken().then(jwtToken => apiGive(jwtToken, this.props.email, to, parseInt(point), message)).then(() => {
            this.props.fetchLoginUser();
            this.setState({ form: Object.assign({}, initState.form) });
        }).catch((err) => console.error(err))
    }
    handleInput(e) {
        const { name, value } = e.target;

        if (this.state.error !== initState.error) this.clearError(name);

        const form = Object.assign({}, this.state.form);
        form[name] = value;
        this.setState({ form });
    }
    validateForm() {
        const { to, point, message } = this.state.form;
        const error = Object.assign({}, initState.error);
        let hasError = false;
        // to
        if (!to) {
            error.to = 'あげる相手を選択してください。';
            hasError = true;
        }
        // point
        if (!point || point <= 0) {
            error.point = 'あげるポイントを入力してください。';
            hasError = true;
        } else if (point > this.props.point_to_give) {
            error.point = 'そんなにあげられません。';
            hasError = true;
        }
        //message
        if (!message) {
            error.message = 'メッセージは必須です。';
            hasError = true;
        }

        this.setState({ error });
        return hasError;
    }
    clearError(name) {
        const error = Object.assign({}, this.state.error);
        error[name] = initState.error[name];
        this.setState({ error });
    }
    render() {
        return (
            <div>
                <h2>あげる</h2>
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>あげる相手</Form.Label>
                        <Form.Control as="select" name="to" value={this.state.form.to} onChange={this.handleInput} isInvalid={!!this.state.error.to}>
                            <option value=''>選択してください</option>
                            {this.state.users.map((user) => this.props.email !== user.email ? <option key={user.email} value={user.email}>{user.name}</option> : null)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {this.state.error.to}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalEmail">
                        <Form.Label>
                            あげるポイント
                         </Form.Label>
                        <Form.Control type="number" placeholder="Point" name="point" value={this.state.form.point} onChange={this.handleInput} isInvalid={!!this.state.error.point} />
                        <Form.Control.Feedback type="invalid">
                            {this.state.error.point}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalEmail">
                        <Form.Label>
                            メッセージ
                         </Form.Label>
                        <Form.Control as="textarea" rows="3" placeholder="メッセージ" name="message" value={this.state.form.message} onChange={this.handleInput} isInvalid={!!this.state.error.message} />
                        <Form.Control.Feedback type="invalid">
                            {this.state.error.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button onClick={this.give} variant="outline-dark">あげる</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Give;