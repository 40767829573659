import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import getJwtToken from "../../../utils/getJwtToken";

import apiAdminUser from '../../../api/admin/user';

const initState = {
    form: {
        username: '',
        email: '',
        admin: false
    }
}

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: initState.form
        }
        this.add = this.add.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }
    componentDidMount() {
    }

    add() {
        const { email, username, admin } = this.state.form;
        getJwtToken().then(jwtToken => apiAdminUser.add(jwtToken, username, email, admin)).then(() => {
            this.setState({ form: Object.assign({}, initState.form) });
        }).catch((err) => console.error(err))
    }

    handleInput(e) {
        const { type, name, value, checked } = e.target;
        const form = Object.assign({}, this.state.form);
        form[name] = type === 'checkbox' ? checked : value;
        this.setState({ form });
    }
    render() {
        return (
            <div>
                <h2>ユーザー追加</h2>
                <p>追加ユーザに招待メールと仮パスワードが送信されます。</p>
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>名前</Form.Label>
                        <Form.Control type="text" placeholder="名前" name="username" value={this.state.form.username} onChange={this.handleInput}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formHorizontalEmail">
                        <Form.Label>メールアドレス</Form.Label>
                        <Form.Control type="email" placeholder="メールアドレス" name="email" value={this.state.form.email} onChange={this.handleInput} />
                    </Form.Group>
                    <Form.Group controlId="formIsAdmin">
                        <Form.Check type="checkbox" label="ワークスペース管理者" name="admin" checked={this.state.form.admin} onChange={this.handleInput} />
                    </Form.Group>
                </Form>
                <Button variant="outline-dark" onClick={this.add}>追加</Button>
            </div>
        );
    }
}
export default Add;