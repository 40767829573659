// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "erapo-staging",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://erapo-staging.s3-website-ap-northeast-1.amazonaws.com"
};


export default awsmobile;
