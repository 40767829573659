const getBaseUrl = require('../util/getBaseUrl');
const baseUrl = getBaseUrl();

const url = baseUrl + '/admin/workspace-config';

const get = (Authorization) => {
    const init = {
        method: 'GET',
        headers: {
            Authorization,
        },
    }
    return fetch(url, init);
}

const update = (Authorization, distribute_point, distribute_freq, aggregate_freq) => {
    const init = {
        method: 'POST',
        headers: {
            Authorization,
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            distribute_point,
            distribute_freq
        })
    }
    return fetch(url, init);
}

export default {
    get,
    update
}