import React, { Component } from 'react';
import getJwtToken from '../../utils/getJwtToken';

import GiveItem from '../common/GiveItem';

import apiGiveLog from '../../api/give_log';

class Gave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gave: []
        }
        this.fetchGave = this.fetchGave.bind(this);
    }
    componentDidMount() {
        this.fetchGave();
    }
    fetchGave() {
        getJwtToken().then(jwtToken => apiGiveLog.get(jwtToken, this.props.email, null))
            .then(res => res.json())
            .then(res => {
                this.setState({ gave: res.length > 0 ? res : [] })
            }).catch(error => {
                console.error(error.response)
            });
    }
    render() {
        return (
            <div>
                <h2>あげた</h2>
                {this.state.gave.map((item, index) => {
                    return <GiveItem key={index} {...item} />
                })}
                {(!this.state.gave || !this.state.gave.length) && <p>データがありません。</p>}
            </div>
        );
    }
}
export default Gave;