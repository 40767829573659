import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Form, Table } from 'react-bootstrap';
import getJwtToken from '../../../utils/getJwtToken';
import apiGiveLog from '../../../api/give_log';

class Aggregate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            log: [],
            form: {}
        }
        this.initForm = this.initForm.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.fetchLog = this.fetchLog.bind(this);
    }
    componentDidMount() {
        this.initForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.form !== this.state.form) {
            this.fetchLog();
        }
    }

    initForm() {
        const current = moment();
        const form = Object.assign({}, this.state.form);
        form['year'] = current.year();
        form['month'] = current.month() + 1;
        this.setState({ form });
    }

    handleInput(e) {
        const { name, value } = e.target;
        const form = Object.assign({}, this.state.form);
        form[name] = value;
        this.setState({ form });
    }

    fetchLog() {
        const { year, month } = this.state.form;
        const date = moment().year(year).month(month - 1);
        const startDate = date.startOf('month').format();
        const endDate = date.endOf('month').format();
        getJwtToken().then(jwtToken => apiGiveLog.aggregatePointByUser(jwtToken, null, startDate, endDate))
            .then(res => res.json())
            .then(res => {
                this.setState({ log: res.length > 0 ? res : [] })
            }).catch(error => {
                console.error(error.response)
            });
    }
    render() {
        return (
            <div>
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>年</Form.Label>
                        <Form.Control as="select" name="year" value={this.state.form.year} onChange={this.handleInput}>
                            <option value=''>選択してください</option>
                            {(() => {
                                const serviceStartYear = 2019;
                                const currentYear = moment().year();
                                const options = [];
                                for (let i = serviceStartYear; i <= currentYear; i++) {
                                    options.push(<option key={i} value={i}>{i}</option>);
                                }
                                return options;
                            })()}
                        </Form.Control>
                        <Form.Label>月</Form.Label>
                        <Form.Control as="select" name="month" value={this.state.form.month} onChange={this.handleInput}>
                            <option value=''>選択してください</option>
                            {(() => {
                                const options = [];
                                for (let i = 1; i <= 12; i++) {
                                    options.push(<option key={i} value={i}>{i}</option>);
                                }
                                return options;
                            })()}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <Table>
                    <thead>
                        <tr><th>メールアドレス</th><th>名前</th><th>もらったポイント</th></tr>
                    </thead>
                    <tbody>
                        {this.state.log.map((item, index) => {
                            return <tr key={index}><td>{item.to_user}</td><td>{item.to_user_name}</td><td>{item.point}</td></tr>
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
}
export default Aggregate;