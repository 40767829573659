const getBaseUrl = require('../util/getBaseUrl');
const baseUrl = getBaseUrl();

const add = (Authorization, name, email, admin) => {
    const url = baseUrl + '/admin/user';
    const init = {
        method: 'POST',
        headers: {
            Authorization,
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            name,
            email,
            admin
        })
    }
    return fetch(url, init);
}

const disable = (Authorization, email) => {
    const url = baseUrl + '/admin/user';
    const init = {
        method: 'DELETE',
        headers: {
            Authorization,
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            email
        })
    }
    return fetch(url, init);
}

export default {
    add,
    disable
}