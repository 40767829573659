import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from '../../assets/img/eraporectangle.png';

const Logo = styled.img`
    height: 30px;
`

const SubNavbar = styled(Navbar)`
    background-color:#111;
    justify-content: center;
`

const SubNavbarText = styled(Navbar.Text)`
    color:#eee !important;
    margin-left:10px;
    margin-right:10px;
`

const SubNavbarTextYellow = styled.span`
    color:#ffe21f !important;
`

const FAIconWhite = styled(FontAwesomeIcon)`
    color: #fff;
    margin-left:10px;
    margin-right:10px;
`

const NavLink = (props) => <Nav.Link className={window.location.pathname === props.to && 'active'} {...props} />

const NavDropdownItem = (props) => <NavDropdown.Item className={window.location.pathname === props.to && 'active'} {...props} />

class ErapoNavbar extends Component {
    render() {
        const { isAdminUser } = this.props;
        const path = window.location.pathname;

        return (<div>
            <Navbar collapseOnSelect expand="lg" bg="main">
                <Navbar.Brand as={Link} to={'/'}><Logo src={logo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="mr-auto">
                        <NavLink as={Link} to={'/give'} >あげる</NavLink>
                        <NavLink as={Link} to={'/gave'} className={path === '/gave' && 'active'}>あげた</NavLink>
                        <NavLink as={Link} to={'/received'} className={path === '/received' && 'active'}>もらった</NavLink>
                    </Nav>
                    <Nav>
                        {isAdminUser &&
                            <NavDropdown title="ワークスペース管理" id="adminDropdown">
                                <NavDropdownItem as={Link} to={'/admin/aggregate'}>集計</NavDropdownItem>
                                <NavDropdownItem as={Link} to={'/admin/user'}>ユーザー一覧</NavDropdownItem>
                                <NavDropdownItem as={Link} to={'/admin/user/add'}>ユーザー追加</NavDropdownItem>
                                <NavDropdownItem as={Link} to={'/admin/workspace-config'}>ワークスペース設定</NavDropdownItem>
                            </NavDropdown>}
                        <NavDropdown title={'こんにちは ' + this.props.username + ' さん'} id="userDropdown">
                            <NavDropdown.Item as={Button} onClick={this.props.signout}>サインアウト</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <SubNavbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <SubNavbarText>あげられるポイント : <SubNavbarTextYellow>{this.props.point_to_give}</SubNavbarTextYellow></SubNavbarText>
                <SubNavbarText>今月もらったポイント : <SubNavbarTextYellow>{this.props.received_point}</SubNavbarTextYellow></SubNavbarText>
                <FAIconWhite icon="sync-alt" onClick={this.props.fetchLoginUser} />
            </SubNavbar>
        </div>
        );
    }
}
export default withRouter(ErapoNavbar);