const getBaseUrl = require('./util/getBaseUrl');
const baseUrl = getBaseUrl();

const get = (Authorization, email) => {
    let url = baseUrl + '/user';
    if (email) url += `?email=${email}`;

    const init = {
        headers: {
            Authorization
        }
    }
    return fetch(url, init);
}

export default {
    get
}