import {
    Auth
} from 'aws-amplify';
/**
 * 現在のセッションのjwtTokenを取得する
 * @returns {Promise<string>} jwtToken
 */
function getJwtToken() {
    return new Promise((resolve, reject) => {
        Auth.currentSession().then((r) => {
            const {
                jwtToken
            } = r.idToken;
            return resolve(jwtToken);
        }).catch((err) => reject(err));
    });
}

export default getJwtToken;